import {Modal, Spinner} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import API from "../../API";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import StudentInformation from "./StudentInformation";
import StudentCourseOverview from "./StudentCourseOverview";
import Code95Tab from "./Code95Tab";
import {CompanyContext} from "../../util/CompanyContext";

/**
 * A modal which displays information about the student in studentId
 * @param studentId The studentId of the student to be displyaed
 * @param setStudentId Callback to set the studentId back to -1 when the modal closes
 */
export default function StudentModal({studentId, show, setShow, setStudentId}) {
    const [data, setData] = useState(null);
    const company = useContext(CompanyContext);

    useEffect(() => {
        if (!!studentId) {
            API.get(`students/${studentId}?expand=enrollments`).then((response) => {
                setData(response.data);
            });
        }
    }, [studentId]);

    return (
        <Modal show={show} onHide={() => {
            setShow(false);
            setTimeout(() => {
                setData(null);
                setStudentId(null);
            }, 10);
        }} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Medewerkersinformatie
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"mb-3"} style={{height:"85vh", overflowY: "overlay"}}>
                {data
                    ?
                    <Tabs defaultActiveKey="information" id={"tabs"}>
                        <Tab eventKey="information" title="Persoonlijk" tabClassName={`text-${company.handle}`}>
                            <StudentInformation student={data}/>
                        </Tab>
                        <Tab eventKey="courses" title="Planning" tabClassName={`text-${company.handle}`}>
                            <StudentCourseOverview student={data}/>
                        </Tab>
                        {data.currentCycle
                            ?
                        <Tab eventKey="code95" title="Code 95" tabClassName={`text-${company.handle}`}>
                            <Code95Tab student={data}/>
                        </Tab>
                            :
                            ""}
                    </Tabs>
                    :
                    <div className={"d-flex"} style={{height: "75vh"}}>
                        <Spinner className={"my-auto mx-auto"} animation={"border"} variant={company.handle}/>
                    </div>

                }
            </Modal.Body>
        </Modal>
    )
}
