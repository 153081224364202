import {Modal} from "react-bootstrap";
import React from "react";
import CourseOverviewCard from "./CourseOverviewCard";
import CourseStudentsOverview from "./CourseStudentsOverview";

/**
 * Creates a modal, which will only display if it's 'props.course' is not null
 * @param course The course the modal needs to display
 * @param setCourseId A callback which the component can call when it closes
 */
export default function PlanningModal({course, show, setShow}) {
    return (
        <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
            {course
            ?
                <>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {course.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"mb-3"}>
                <CourseOverviewCard course={course} />
                <CourseStudentsOverview course={course} />
            </Modal.Body>
            </>
            :
            ""}
        </Modal>
    )
}