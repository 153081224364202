import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import ContactPerson from "./ContactPerson";
import GeneralCompanyInformation from "./GeneralCompanyInformation";

/**
 * Displays general information about the company and the contact persons
 * @param company The company to be displayed
 */
export default function CompanyInformation({company}) {
    return (
        <Container>
            <GeneralCompanyInformation company={company}/>
            {company.contactPersons &&
            <>
                <Row className={""}>
                    <Col className={"my-3 px-0"}>
                        <h2 className={"display-4"}>{company.contactPersons.length === 1 ? "Contactpersoon:" : "Contactpersonen:"}</h2>
                    </Col>
                </Row>
                <Row>
                    {company.contactPersons.map((person, index) =>
                        <ContactPerson key={index} person={person}/>)
                    }
                </Row>
            </>
            }
        </Container>
    )
}