import {Badge} from "react-bootstrap";
import React from "react";
import moment from "../../Config"

/**
 * The component which renders the current theory- and practiceCredits in a nice progress bar, taking the endDate into account
 * @param credits The amount of credits in the current cycle
 * @param endDate The endDate of the current cycle, current date by default
 * @param width The width of this component, 100 by default
 * @param practices Whether the student has completed their practice course
 * @returns {*} The jsx of the component
 */
export default function Code95Progress({credits, endDate = moment(), width = 100, practice}) {
    const duration = moment.duration(endDate.diff(moment()));
    const yearsUntilEnd = duration.as('years');

    const riskyYearLimit = 4.5 - parseInt((credits / 7), 10);

    let variant;

    if (credits >= 35 && practice) {
        variant = 'success';
    } else {
        variant = yearsUntilEnd < riskyYearLimit ? 'danger' : 'success';
    }

    const getStyle = index => ({
        flexGrow: 1,
        ...((credits < (index * 7)) && {opacity: 0.3}),
    });

    return (
        <div className={`w-${width}`}>
            <div
                className='w-100'
                style={{
                    display: 'flex',
                }}
            >
                <Badge variant={variant} style={getStyle(1)} className="badge-rounded-left">&nbsp;</Badge>
                <Badge variant={variant} style={getStyle(2)} className="ml-1 badge-not-rounded">&nbsp;</Badge>
                <Badge variant={variant} style={getStyle(3)} className="ml-1 badge-not-rounded">&nbsp;</Badge>
                <Badge variant={variant} style={getStyle(4)} className="ml-1 badge-not-rounded">&nbsp;</Badge>
                <Badge variant={variant} style={getStyle(5)} className="ml-1 badge-rounded-right">&nbsp;</Badge>
            </div>
            <div className={"text-muted text-center"}>{credits} / 35</div>
        </div>
    );
}