import InformationItem from "../Utils/cards/InformationItem";
import React from "react";
import ListInformationCard from "../Utils/cards/ListInformationCard";
import moment from "../../Config";

/**
 * Gives general information about the given course in an InformationCard
 * @param course The course to be displayed
 */
export default function CourseOverviewCard({course}) {
    const slot = course.parts[0].slots[0];
    return (
        <ListInformationCard header={"Cursus details"}>
            <InformationItem title={"Naam:"} content={course.name}/>
            <InformationItem title={"Datum"} content={moment(slot.startDate).format("L")}/>
            <InformationItem title={"Tijdstip"}
                             content={`${moment(slot.startDate).format("LT")} - ${moment(slot.endDate).format("LT")}`}/>
            {slot.location.address ? <InformationItem title={"Locatie"} content={slot.location.address.city}/> : ""}
        </ListInformationCard>
    )
}