import React, {useContext, useEffect, useMemo} from "react";
import API, {DATION_API} from "../API";
import getCapacity from "../components/Utils/GetCapacity";
import moment from "../Config";
import {Spinner} from "react-bootstrap";
import CourseTable from "../components/CourseInformation/CourseTable";
import {useData} from "../components/Hooks/DataHook";
import {CompanyContext} from "../util/CompanyContext";

/**
 * The component for the course (or "aanbod") page.
 * @returns {*} The jsx of the component
 */
export default function CoursePage() {

    const {
        courseData,
        setCourseData,
        companyCourseData,
        setCompanyCourseData,
        currentCompany
    } = useData();

    const company = useContext(CompanyContext);

    useEffect(() => {
        if (!courseData.done) {
            DATION_API.get(`/course-instances`, {
                params: {
                    remainingAttendeeCapacity: 1,
                    startDateAfter: moment().format()
                }
            })
                .then((response) => {
                    setCourseData({done: true, data: response.data});
                });
        }
    }, []);

    // Retrieve the course data from the api if it has not yet been set
    useEffect(() => {
        if (currentCompany) {
            API.get(`/companies/${currentCompany}/course-instances`, {
                params: {
                    expand: "students",
                    startDateAfter: moment().format()
                }
            })
                .then((response) => setCompanyCourseData({done: true, data: response.data}));
        }
    }, [currentCompany]);

    const mergeData = useMemo(() => {
        let ids = [];
        for (let elem of companyCourseData.data) ids.push(elem.id);
        return companyCourseData.data.filter((elem) => getCapacity(elem) > 0).concat(courseData.data.filter(elem => !ids.includes(elem.id)));
    }, [courseData, companyCourseData]);

    return (
        !courseData.done || !companyCourseData.done
            ?
            <div className={"vh-100 d-flex flex-column transition"}>
                <Spinner animation="border" className={"my-auto mx-auto"} variant={company.handle}/>
            </div>
            :
            <CourseTable courseData={mergeData}/>
    );
}
