import React, { useContext, useEffect, useState } from "react";
import PlanningTable from "../components/Planning/PlanningTable";
import API from "../API";
import PlanningModal from "../components/Planning/PlanningModal";
import Spinner from "react-bootstrap/Spinner";
import moment from "../Config";
import { useData } from "../components/Hooks/DataHook";
import { CompanyContext } from "../util/CompanyContext";

/**
 * Page to display the planning of the company's courses. Will display a spinner until the data is not empty (i.e. the query is finished)
 */
export default function PlanningPage() {
    const [courseId, setCourseId] = useState(-1);
    const [activeCourse, setActiveCourse] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { companyCourseData, setCompanyCourseData, currentCompany } = useData();

    const company = useContext(CompanyContext);

    const getCourseById = (courseId) => {
        return companyCourseData.data.find(element => element.id === courseId);
    }

    useEffect(() => {
        if (courseId === -1) {
            setActiveCourse(null);
        } else {
            setActiveCourse(getCourseById(courseId))
        }
    }, [courseId]);

    useEffect(() => {
        if (currentCompany) {
            API.get(`/companies/${currentCompany}/course-instances`, {
                params: {
                    expand: "students",
                    startDateAfter: moment().format()
                }
            })
                .then((response) => setCompanyCourseData({ done: true, data: response.data }));
        }
    }, [currentCompany]);

    return (
        !companyCourseData.done
            ?
            <div className={"vh-100 d-flex flex-column transition"}>
                <Spinner animation="border" className={"my-auto mx-auto"} variant={company.handle} />
            </div>
            :
            <>
                <PlanningTable
                    setCourseId={setCourseId}
                    setShowModal={setShowModal}
                />
                <PlanningModal
                    course={activeCourse}
                    show={showModal}
                    setShow={setShowModal}
                />
            </>
    )
}