import React, { useContext, useEffect, useState } from 'react';
import EmployeeTable from "../components/EmployeeOverview/EmployeeTable";
import StudentModal from "../components/StudentInformation/StudentModal";
import Spinner from "react-bootstrap/Spinner";
import { useData } from "../components/Hooks/DataHook";
import { CompanyContext } from "../util/CompanyContext";

/**
 * Page to display an overview of the employees, will display a spinner until the data is not empty
 * @returns {*} The jsx of the component
 */
export default function EmployeePage() {
    const [modalStudentId, setModalStudentId] = useState(-1);
    const [showModal, setShowModal] = useState(false);
    const { employeeData, updateEmployeeData, currentCompany } = useData();

    const company = useContext(CompanyContext);

    useEffect(() => {
        if (currentCompany) {
            updateEmployeeData();
        }
    }, [currentCompany])

    return (
        !employeeData.done
            ?
            <div className={"vh-100 d-flex flex-column transition"}>
                <Spinner animation="border" className={"my-auto mx-auto"} variant={company.handle} />
            </div>
            : <>
                <EmployeeTable
                    setStudentId={setModalStudentId}
                    setShowModal={setShowModal}
                />
                <StudentModal
                    studentId={modalStudentId}
                    setStudentId={setModalStudentId}
                    show={showModal}
                    setShow={setShowModal}
                />
            </>
    );
}
