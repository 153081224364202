import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {Form} from "react-bootstrap";
import React, {useContext, useEffect} from "react";
import {Link, NavLink} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {companyHandle, logoSource} from "../Config";
import {CompanyContext} from "../util/CompanyContext";
import {useData} from "./Hooks/DataHook";

/**
 * Main navbar of the application, displays the svg of the logged in company
 */
export default function MainNavBar() {
    const company = useContext(CompanyContext);
    const {companyInformation, currentCompany, setCurrentCompany} = useData();

    useEffect(() => {
        if (companyInformation.done) {
            setCurrentCompany(companyInformation.data[0].id);
        }
    }, [companyInformation]);

    return (
        <Navbar collapseOnSelect bg="light" expand="lg" variant={"light"}>
            <Navbar.Brand as={Link} to={"/"} href="/">
                <img width={"100"} src={logoSource} alt={companyHandle}/>
            </Navbar.Brand>
            {
                companyInformation.done && companyInformation.data.length > 1 &&
                <Nav className={"ml-auto mr-3 d-lg-none"}>
                    <Form.Control as={"select"} value={currentCompany}
                                  onChange={item => setCurrentCompany(item.target.value)}>
                        {
                            [...companyInformation.data.map(company => ({
                                name: company.name,
                                value: parseInt(company.id)
                            }))]
                                .map((opt, index) => (
                                    <option value={opt.value} key={opt.value + index}>{opt.name}</option>
                                ))
                        }
                    </Form.Control>
                </Nav>
            }
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to={"/"} href="/">Medewerkers</Nav.Link>
                    <Nav.Link as={NavLink} to={"/planning"} href="/planning">Planning</Nav.Link>
                    <Nav.Link as={NavLink} to={"/courses"} href="/courses">Aanbod</Nav.Link>
                    <Nav.Link as={NavLink} to={"/company-data"} href="/company-data">Informatie</Nav.Link>
                    <Nav.Link as={NavLink} to={"/about-us"} href="/about-us">Over ons</Nav.Link>
                </Nav>
                {
                    companyInformation.done && companyInformation.data.length > 1 &&
                        <Nav className={"ml-auto mr-3 d-none d-lg-flex"}>
                            <Form.Control as={"select"} value={currentCompany}
                                          onChange={item => setCurrentCompany(item.target.value)}>
                                {
                                    [...companyInformation.data.map(company => ({
                                        name: company.name,
                                        value: parseInt(company.id)
                                    }))]
                                        .map((opt, index) => (
                                            <option value={opt.value} key={opt.value + index}>{opt.name}</option>
                                        ))
                                }
                            </Form.Control>
                        </Nav>
                }
                <Nav className={companyInformation.done && companyInformation.data.length > 1 ? "" : "ml-auto"}>
                        <Button onClick={() => {
                            localStorage.clear();
                            window.location.reload()
                        }} variant={`outline-${company.handle}`}>
                            Log uit
                        </Button>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}