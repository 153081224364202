import React from "react";
import {Row} from "react-bootstrap";
import moment from "../../Config";
import ListInformationCard from "../Utils/cards/ListInformationCard";
import InformationItem from "../Utils/cards/InformationItem";

const OrderOverview = ({course}) => {
    const slot = course.parts[0].slots[0];
    return (
        <Row noGutters className={"w-100"}>
            <ListInformationCard header={"Cursus details"}>
                <InformationItem title={"Naam:"} content={course.name} />
                <InformationItem title={"Datum:"} content={moment(course.startDate).format("L")} />
                <InformationItem title={"Tijd:"} content={`${moment(slot.startDate).format("LT")} - ${moment(slot.endDate).format("LT")}`} />
                <InformationItem title={"Locatie:"} content={course.parts?.[0].slots?.[0].location.address.city || ""} />
                <InformationItem title={"Capaciteit:"} content={course.remainingAttendeeCapacity} />
            </ListInformationCard>
        </Row>

    );
};

export default OrderOverview;