import {useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {Container, Row, Table} from "react-bootstrap";
import React, {useMemo} from "react";
import moment from "../../Config";
import NoData from "../Utils/NoData";
import Pagination from "../Tables/Pagination";
import getCapacity from "../Utils/GetCapacity";
import TableHeader from "../Tables/TableHeader";
import TableBody from "../Tables/TableBody";
import {useData} from "../Hooks/DataHook";

/**
 * Displays the planning of the company's courses in a table, which can be clicked
 * @param tableData The data of the table
 * @param setCourseId Callback to open the modal based on which row is clicked
 */
export default function PlanningTable({setCourseId, setShowModal}) {
    const {companyCourseData} = useData();
    const data = useMemo(() => (companyCourseData.data), [companyCourseData]);

    const columns = useMemo(() => [
        {
            Header: 'id',
            accessor: 'id'
        },
        {
            Header: 'Datum',
            id: 'startDate',
            accessor: course => course.startDate,
            Cell: ({cell: {value}}) => value ? moment(value).format('L LT') : <NoData/>
        },
        {
            Header: 'CCV code',
            accessor: 'ccvCode',
            Cell: ({cell: {value}}) => value ? value : <NoData/>
        },
        {
            Header: 'Training',
            accessor: 'name',
            Cell: ({cell: {value}}) => value ? value : <NoData/>
        },
        {
            Header: 'Locatie',
            id: "location",
            accessor: course => course.parts[0].slots[0].location.address ? course.parts[0].slots[0].location.address.city :
                <NoData/>
        },
        {
            Header: 'Deelnemers',
            accessor: course => course.companyReservations[0]?.amountPlanned || 0,
            Cell: ({cell: {value}}) => value ? value : <NoData/>
        },
        {
            Header: 'Plaatsen vrij',
            id: "free_spots",
            accessor: getCapacity,
            Cell: ({cell: {value}}) => value ? value : <NoData />
        }
    ], []);

    function onRowClick(row) {
        setCourseId(row.original.id);
        setShowModal(true);
    }

    const getRowProps = row => ({
        onClick: () => onRowClick(row),
        style: {
            cursor: "pointer"
        }
    });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize, globalFilter},
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                sortBy: [
                    {
                        id: 'startDate',
                        desc: false
                    }
                ],
                hiddenColumns: ['id']
            },

        },
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    return (
        <Container fluid={"xl"} className={"transition"}>
            <Row className={"my-3"}>
                <h4 className={"display-4"}>Planning</h4>
                <Table responsive striped bordered hover {...getTableProps()}>
                    <TableHeader headerGroups={headerGroups} setGlobalFilter={setGlobalFilter}
                                 globalFilter={globalFilter}/>
                    <TableBody pageSize={pageSize} page={page} pageOptions={pageOptions} pageIndex={pageIndex}
                               noResults={"Geen medewerkers ingepland"} data={data} prepareRow={prepareRow}
                               getTableBodyProps={getTableBodyProps} getRowProps={getRowProps}/>


                </Table>
            </Row>
            <Pagination canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        gotoPage={gotoPage}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        setPageSize={setPageSize}/>
        </Container>
    )
}