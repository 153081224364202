import React from 'react';
import './style/App.css';
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import EmployeePage from "./pages/EmployeePage";
import 'bootstrap/dist/css/bootstrap.min.css';
import CompanyInformationPage from "./pages/CompanyInformationPage";
import MainNavBar from "./components/MainNavBar";
import "./style/Custom.scss";
import CoursePage from "./pages/CoursePage";
import PlanningPage from "./pages/PlanningPage";
import { ProvideData } from "./components/Hooks/DataHook";
import { CompanyContextProvider, getCompany } from "./util/CompanyContext";
import SendPageViewRoute from "./components/GoogleAnalytics";
import AboutUs from "./pages/AboutUs";
import SnackbarProvider from "react-simple-snackbar";

export const browserHistory = createBrowserHistory();

/**
 * Main component which stores data in its states for faster switching between pages.
 * @returns {*} The jsx of the component
 */
const App = () => (
    <Router history={browserHistory}>
        <CompanyContextProvider value={getCompany()}>
            <SnackbarProvider>
                <div>
                    <ProvideData>
                        <MainNavBar />
                        <Switch>
                            <Route path="/about-us" render={props => <AboutUs {...props} />} />
                            <Route path="/company-data" render={props => <CompanyInformationPage {...props} />} />
                            <Route path="/courses"
                                render={props => <CoursePage {...props} />} />
                            <Route path={"/planning"}
                                render={props => <PlanningPage {...props} />} />
                            <Route path="/"><EmployeePage /></Route>
                        </Switch>
                        <SendPageViewRoute />
                    </ProvideData>
                </div>
            </SnackbarProvider>
        </CompanyContextProvider>
    </Router>
);

export default App;
