import React, { createContext, useContext, useEffect, useState } from "react";
import { checkToken } from "../../util/Authentication";
import API, { authUrlKey } from "../../API";
import { administrationId } from "../../Config";

const dataContext = createContext(null);

const useProvideData = () => {
    const [companyInformation, setCompanyInformation] = useState({ done: false, data: [] });
    const [employeeData, setEmployeeData] = useState({ done: false, data: [] });
    const [courseData, setCourseData] = useState({ done: false, data: [] });
    const [companyCourseData, setCompanyCourseData] = useState({ done: false, data: [] });
    const [drivingSchoolData, setDrivingSchoolData] = useState({ done: false, data: [] });
    const [currentCompany, setCurrentCompany] = useState(undefined);

    const updateEmployeeData = () => {
        // Adds empty placholder data for students that do not have a current cycle
        const addPlaceHolders = (data) => {
            return data.map((value) => {
                if (!value.currentCycle) {
                    value.currentCycle = {
                        endDate: null,
                        theoryCredits: 0,
                        practiceCredits: false
                    }
                    return value;
                } else {
                    return value;
                }
            });
        }

        API.get(`/companies/${currentCompany}/students`)
            .then((response) => {
                setEmployeeData({ done: true, data: addPlaceHolders(response.data.filter((row) => !row.archived)) });
            });
    };

    const updateDrivingSchoolData = () => {
        API.get(`driving-school-information/${administrationId}`)
            .then(r => {
                setDrivingSchoolData({ done: true, data: r.data });
            });
    };

    useEffect(() => {
        const hasToken = checkToken();
        if(hasToken) {
            API.get('companies?expand=contactPersons')
                .then((response) => setCompanyInformation({ done: true, data: response.data }));
        } else {
            window.location = authUrlKey
        }
    }, []);

    return {
        companyInformation, setCompanyInformation,
        employeeData, setEmployeeData,
        courseData, setCourseData,
        companyCourseData, setCompanyCourseData,
        drivingSchoolData, setDrivingSchoolData,
        updateEmployeeData, updateDrivingSchoolData,
        currentCompany, setCurrentCompany
    };
}

export const ProvideData = ({ children }) => {
    const companyInformation = useProvideData();
    return <dataContext.Provider value={companyInformation}>{children}</dataContext.Provider>;
}

export const useData = () => useContext(dataContext);
