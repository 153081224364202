import {useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import {Button, Row, Table} from "react-bootstrap";
import React, {useContext, useMemo, useState} from "react";
import Pagination from "../Tables/Pagination";
import NoData from "../Utils/NoData";
import getCapacity from "../Utils/GetCapacity";
import TableHeader from "../Tables/TableHeader";
import TableBody from "../Tables/TableBody";
import moment from "../../Config";
import ReservationsModal from "./ReservationsModal";
import {CompanyContext} from "../../util/CompanyContext";

/**
 * The table used to display the currently available courses on the CoursePage
 * @param courseData The data retrieved by CoursePage.js
 * @returns {*} The jsx of the component
 */
export default function CourseTable({courseData}) {
    const data = useMemo(() => (courseData), [courseData]);

    const [orderCourse, setOrderCourse] = useState({});
    const [showOrderModal, setShowOrderModal] = useState(false);

    const company = useContext(CompanyContext);

    const columns = useMemo(() => [
        {
            Header: 'Datum',
            id: 'startDate',
            accessor: course => course.startDate,
            Cell: ({cell: {value}}) => value ? moment(value).format('L LT') : <NoData/>
        },
        {
            Header: 'CCV code',
            accessor: 'ccvCode',
            Cell: ({cell: {value}}) => value ? value : <NoData/>
        },
        {
            Header: 'Cursus',
            accessor: 'name',
            Cell: ({cell: {value}}) => value ? value : <NoData/>,
        },
        {
            Header: 'Locatie',
            id: "location",
            accessor: course => course.parts[0].slots[0].location.address ? course.parts[0].slots[0].location.address.city : '',
            Cell: ({cell: {value}}) => value ? value : <NoData/>
        },
        {
            Header: 'Plaatsen vrij',
            id: "free_spots",
            accessor: getCapacity,
            Cell: ({cell: {value}}) => value ? value : <NoData/>,
        },
        {
            Header: '',
            id: "reserve",
            accessor: course => (
                <Button
                    variant={company.handle}
                    block
                    onClick={() => {
                        setOrderCourse(course);
                        setShowOrderModal(true);
                    }}
                >
                    Reserveer
                </Button>
            )
        }
    ], [company.handle]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize, globalFilter},
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                sortBy: [
                    {
                        id: 'startDate',
                        desc: false
                    }
                ],
            }
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
    );
    return (
        <>
            <div className={"container-xl transition"}>
                <Row className={"my-3"}>
                    <h4 className={"display-4"}>Aanbod</h4>
                    <Table className={"mb-0"} responsive striped bordered hover {...getTableProps()}>
                        <TableHeader
                            setGlobalFilter={setGlobalFilter} globalFilter={globalFilter}
                            headerGroups={headerGroups}
                        />
                        <TableBody
                            getTableBodyProps={getTableBodyProps} prepareRow={prepareRow}
                            noResults={"Geen cursusaanbod beschikbaar"} page={page} data={data}
                            pageOptions={pageOptions} pageIndex={pageIndex} pageSize={pageSize}
                        />
                    </Table>
                </Row>
                <Pagination
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </div>
            <ReservationsModal
                show={showOrderModal}
                setShow={setShowOrderModal}
                course={orderCourse}
                setCourse={setOrderCourse}
            />
        </>
    );
}