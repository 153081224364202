import React, {useContext} from "react";

import CompanyInformation from "../components/CompanyInformation/CompanyInformation";
import Spinner from "react-bootstrap/Spinner";
import {useData} from "../components/Hooks/DataHook";
import {CompanyContext} from "../util/CompanyContext";

/**
 * Page to display the company information. Displays a spinner until the companyInformation is not empty (i.e. the query is finished)
 */
export default function CompanyInformationPage() {
    const {companyInformation, currentCompany} = useData();
    const company = useContext(CompanyContext);

    return (
        !companyInformation.done
            ?
            <div className={"vh-100 d-flex flex-column transition"}>
                <Spinner animation="border" className={"my-auto mx-auto"} variant={company.handle}/>
            </div>
            :
            <div className={"bg-gray transition"}>
                {companyInformation.data.filter(item => parseInt(item.id) === parseInt(currentCompany)).map((company, index) =>
                    <CompanyInformation company={company} key={index}/>
                )}
            </div>
    )
}