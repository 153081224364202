import InformationItem from "../Utils/cards/InformationItem";
import moment from "../../Config";
import Code95Progress from "../EmployeeOverview/Code95Progress";
import React from "react";
import ListInformationCard from "../Utils/cards/ListInformationCard";

/**
 * Displays the current code95 cycle of the given student
 * @param student The student to be displayed
 */
export default function Code95Information({student}) {
    return (
        <ListInformationCard header={"Code 95"}>
            <InformationItem title="Cyclus:"
                             content={`${moment(student.currentCycle.startDate).format("L")} tot ${moment(student.currentCycle.endDate).format("L")}`}/>
            <InformationItem title="Voortgang:"
                             content={<Code95Progress credits={student.currentCycle.totalCredits} endDate={moment(student.currentCycle.endDate)} width={50} practice={student.currentCycle.practiceCredits}/>}/>
            <InformationItem title="Praktijk:" content={student.currentCycle.practiceCredits ?
                <i className={"text-success material-icons"}>done</i> :
                <i className={"text-danger material-icons"}>close</i>}/>
        </ListInformationCard>
    )
}