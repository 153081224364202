import InformationItem from "../Utils/cards/InformationItem";
import React from "react";
import moment from "../../Config";
import ListInformationCard from "../Utils/cards/ListInformationCard";
import formatName from "../Utils/FormatName";
import IsBelgian from "../Utils/IsBelgian";

/**
 * Displays general information about the given student
 * @param student The student to be displayed
 */
export default function GeneralStudentInformation({student}) {
    return (
        <ListInformationCard header={"Informatie"}>
            <InformationItem title="Naam:" content={formatName(student)} />
            {IsBelgian() && <InformationItem title={"Rijksregisternummer:"} content={student.nationalRegistryNumber} /> }
            <InformationItem title="E-mailadres:" content={student.emailAddress} />
            <InformationItem title="Telefoonnummer:" content={student.mobileNumber || student.phoneNumber} />
            <InformationItem title="Geboortedatum:" content={moment(student.dateOfBirth).format("L")} />
            {student.cbrId && <InformationItem title="CBR nummer:" content={student.cbrId} />}
            {student.drivingLicense && <InformationItem title="Verloopdatum rijbewijs:" content={ moment(student.drivingLicense.expiryDate).format("L")} />}
        </ListInformationCard>
    )
}