import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useData } from "../Hooks/DataHook";
import moment, { companyHandle } from "../../Config";
import { BACKEND } from "../../API";
import DateInput from "../Utils/forms/DateInput";
import CustomField from "../Utils/forms/CustomField";
import { CompanyContext } from "../../util/CompanyContext";
import { useSnackbar } from 'react-simple-snackbar'
import { danger, success } from "../Utils/AlertStyles";
import CustomCard from "../Utils/cards/CustomCard";
import RequiredStar from "../Utils/forms/RequiredStar";
import IsBelgian from "../Utils/IsBelgian";

const AddEmployeeModal = ({ show, setShow }) => {
    const { updateEmployeeData, currentCompany } = useData();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [drivingLicenseExpiry, setDrivingLicenseExpiry] = useState("");
    const [email, setEmail] = useState("");
    const [employeeCompany, setEmployeeCompany] = useState("");
    const [nationalRegistryNumber, setNationalRegistryNumber] = useState("");

    const [addSuccess] = useSnackbar(success);
    const [addAlert] = useSnackbar(danger);

    const company = useContext(CompanyContext);

    useEffect(() => {
        if (currentCompany) {
            setEmployeeCompany(currentCompany);
        }
    }, [currentCompany]);

    const clearFields = () => {
        updateEmployeeData();
        setFirstName("");
        setLastName("");
        setDateOfBirth("");
        setDrivingLicenseExpiry("");
        setEmail("");
    }

    const addEmployee = (event) => {
        setShow(false);
        event.preventDefault();
        const data = {
            firstName: (firstName || undefined),
            lastName,
            dateOfBirth: (dateOfBirth ? moment(dateOfBirth).format() : undefined),
            drivingLicense: (drivingLicenseExpiry ? { expiryDate: moment(drivingLicenseExpiry).format() } : undefined),
            company: (employeeCompany ? { id: employeeCompany } : undefined),
            emailAddress: (email || undefined),
            companyHandle,
            nationalRegistryNumber: (nationalRegistryNumber || undefined)
        }

        BACKEND.post('/employee/add', JSON.stringify(data))
            .then(r => {
                if (r.status === 200) {
                    addSuccess(`Medewerker${r.data.firstName ? ` ${r.data.firstName}` : ""}${r.data.lastName ? ` ${r.data.lastName}` : ""} succesvol toegevoegd`);
                    clearFields();
                } else {
                    addAlert("Er ging iets mis");
                }
            })
            .catch(e => {
                if (!e.response) {
                    addAlert("Server error, neem contact op met Dation om het te verhelpen");
                } else if (e.response.status === 409) {
                    addAlert(e.response.data.detail || "Er ging iets mis bij het aanmaken van de medewerker");
                } else if (e.response.status === 401) {
                    addAlert("Je hebt geen toegang, probeer opnieuw in te loggen")
                } else if (e.response.status === 400 && e.response.data?.error === "missing-handle") {
                    addAlert("Dit bedrijf heeft geen optie om medewerkers toe te voegen, neem contact op met dation om dit te activeren");
                } else if (e.response.status === 400) {
                    addAlert("De ingevulde waardes zijn ongeldig, kijk of er iets ontbreekt");
                } else {
                    addAlert("Er ging iets mis");
                }
            })
    }
    return (
        <Modal show={show} onHide={() => setShow(false)} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title id={"contained-modal-title-vcenter"}>
                    Medewerker toevoegen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"mb-3"}>
                <CustomCard header={"Medewerker informatie"}>
                    <Form onSubmit={addEmployee}>
                        <CustomField label={"Voornaam"} setValue={setFirstName} value={firstName} />
                        <CustomField label={"Achternaam"} setValue={setLastName} value={lastName} />
                        <Form.Group>
                            <Form.Label>Geboortedatum<RequiredStar /></Form.Label>
                            <DateInput value={dateOfBirth} onChange={e => setDateOfBirth(e)} required />
                        </Form.Group>
                        {
                            IsBelgian() && <CustomField label={"Rijksregisternummer"} setValue={setNationalRegistryNumber} value={nationalRegistryNumber} />
                        }
                        <CustomField
                            label={"E-mailadres"}
                            pattern={"[\\\d\\\w._%+-]+@[\\\d\\\w.-]+\\\.[\\\w]{2,4}"}
                            title={"Vul een geldig e-mailadres in"}
                            setValue={setEmail} value={email}
                        />
                        <Form.Group>
                            <Form.Label>Verloopdatum Rijbewijs</Form.Label>
                            <DateInput value={drivingLicenseExpiry} onChange={e => setDrivingLicenseExpiry(e)}
                                required={false} />
                        </Form.Group>
                        <div className={"d-flex w-100 justify-content-between"}>
                            <Button onClick={() => {
                                clearFields();
                                setShow(false);
                            }} variant={"light"}>Annuleren</Button>
                            <Button variant={company.handle} type={"submit"}>Toevoegen</Button>
                        </div>
                    </Form>
                </CustomCard>
            </Modal.Body>
        </Modal>
    );
};

export default AddEmployeeModal;