import {Card, Col} from "react-bootstrap";
import React, {useContext} from "react";
import {CompanyContext} from "../../../util/CompanyContext";
import {css} from "emotion";

/**
 * General way of displaying information in a card
 * @param xs The size of the card in xs
 * @param md The size of the card in md
 * @param lg The size of the card in lg
 * @param header The header of the card
 * @param children The items of the card
 */
export default function CustomCard({xs = 12, md = 12, lg = 12, header, children}) {
    const company = useContext(CompanyContext);
    return (
        <Col md={md} lg={lg} xs={xs} className={"mb-3"}>
            <Card bg={company.handle} text={"light"}>
                <Card.Header>
                    <h4>{header}</h4>
                </Card.Header>
                <Card.Body className={"text-dark bg-white " + css`
                  border-radius: calc(0.25rem - 1px);
                `}>
                    {children}
                </Card.Body>
            </Card>
        </Col>
    )
}