import Row from "react-bootstrap/Row";
import {Container} from "react-bootstrap";
import React from "react";
import Code95Information from "./Code95Information";

/**
 * Tab in the student modal which contains the code95 information
 * @param student the student to be dipslayed
 * @returns {*}
 * @constructor
 */
export default function Code95Tab({student}) {
    return (
        <Container className={"mt-3"}>
            <Row>
                <Code95Information student={student}/>
            </Row>
        </Container>
    )
}