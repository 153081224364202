import React, {useMemo} from "react";
import {Row, Table} from "react-bootstrap";
import {usePagination, useSortBy, useTable} from "react-table";
import moment from "../../Config";
import NoData from "../Utils/NoData";
import Container from "react-bootstrap/Container";
import TableHeader from "../Tables/TableHeader";
import TableBody from "../Tables/TableBody";
import Pagination from "../Tables/Pagination";

/**
 * Gives an overview of the given student's course
 * @param student The student to be displayed
 */
export default function StudentCourseOverview({student}) {
    const data = useMemo(() => (student.enrollments), [student.enrollments]);

    const columns = useMemo(() => [
        {
            Header: 'Datum',
            accessor: 'courseInstance.startDate',
            Cell: ({cell: {value}}) => value ? moment(value).format('L'): <NoData />
        },
        {
            Header: 'Training',
            accessor: 'courseInstance.name',
        },
        {
            Header: 'Code\u00a095 uren',
            accessor: 'code95Hours',
        },
        {
            Header: 'Verloopdatum',
            accessor: 'expiryDate',
            Cell: ({cell: {value}}) => value ? moment(value).format('L') : <NoData />,
        }
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                sortBy: [
                    {
                        id: 'courseInstanceStartDate',
                        desc: true
                    }
                ]
            },

        },
        useSortBy,
        usePagination,
    );

    return (
        <Container>
            <Row className={"mt-3"}>
                <Table responsive striped bordered hover {...getTableProps()}>
                    <TableHeader headerGroups={headerGroups} />
                    <TableBody getTableBodyProps={getTableBodyProps} prepareRow={prepareRow} data={data} noResults={"Geen trainingen gevonden"} page={page} pageIndex={pageIndex} pageOptions={pageOptions} pageSize={pageSize} />
                </Table>
            </Row>
            <Pagination pageSize={pageSize} pageOptions={pageOptions} pageIndex={pageIndex} pageCount={pageCount} canNextPage={canNextPage} canPreviousPage={canPreviousPage} gotoPage={gotoPage} nextPage={nextPage} paginationSize={false} previousPage={previousPage} />
        </Container>
    )
}
