import {useData} from "../components/Hooks/DataHook";
import React, {useContext, useEffect} from "react";
import {CompanyContext} from "../util/CompanyContext";
import Spinner from "react-bootstrap/Spinner";
import DrivingSchoolInformation from "../components/CompanyInformation/DrivingSchoolInformation";

/**
 * Page to display the about us. Displays a spinner until the driving school information is not empty (i.e. the query is finished)
 */
export default function AboutUs() {
    const {drivingSchoolData, updateDrivingSchoolData} = useData();
    const company = useContext(CompanyContext);

    useEffect(() => {if (!drivingSchoolData.done) updateDrivingSchoolData()}, []);

    return (
        !drivingSchoolData.done
            ?
            <div className={"vh-100 d-flex flex-column transition"}>
                <Spinner animation="border" className={"my-auto mx-auto"} variant={company.handle}/>
            </div>
            :
            <div className={"bg-gray transition"}>
                <DrivingSchoolInformation/>
            </div>
    )
}