import React from "react";

/**
 * This component is displayed in the rendering of EmployeeTable
 * @param props The props containing certificationRisk
 * @returns {*} The jsx of the component
 */
export default function CertificationRisk(props) {
    let certificationRisk = () => {
        switch (props.certificationRisk) {
            case 1:
                return {color: "success", text: "Laag"};
            case 2:
                return {color: "warning", text: "Middel"};
            case 3:
                return {color: "danger", text: "Hoog"};
            default:
                return {color: "primary", text: ""};
        }
    };

    let {color, text} = certificationRisk();
    return <span className={"text-" + color}>{text}</span>;
}