import {Form} from "react-bootstrap";
import React from "react";
import RequiredStar from "./RequiredStar";

const CustomField = ({
                         label,
                         placeholder = "",
                         value,
                         setValue,
                         type = "text",
                         required = true,
                         onChange = item => setValue(item.target.value),
                         as = undefined,
                         ...props
                     }) => (
    <Form.Group as={as}>
        <Form.Label>{label}{required && <RequiredStar/>}</Form.Label>
        <Form.Control
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            required={required}
            {...props}
        />
    </Form.Group>
);

export default CustomField;