import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import InformationItem from "../Utils/cards/InformationItem";
import ListInformationCard from "../Utils/cards/ListInformationCard";
import {useData} from "../Hooks/DataHook";

const DrivingSchoolInformation = () => {
    const {drivingSchoolData: {data}} = useData();
    const address = data.address;

    return (
        <Container>
            <Row>
                <Col xs={12} className={"my-3 px-0"}>
                    <h2 className={"display-4"}>Opleider informatie:</h2>
                </Col>
            </Row>
            <Row>
                <ListInformationCard md={12} lg={6} header={"Algemeen"}>
                    <InformationItem title={"Naam:"} content={data.name} />
                    <InformationItem title={"Telefoonnummer:"} content={data.phoneNumber} />
                    <InformationItem title={"E-mailadres:"} content={data.emailAddress} />
                    <InformationItem title={"Website:"} content={<a href={`//${data.website}`}>{data.website}</a>} />
                </ListInformationCard>
                <ListInformationCard md={12} lg={6} header={"Adresgegevens"}>
                    <InformationItem title="Adres:" content={`${address.streetName} ${address.houseNumber}`} />
                    <InformationItem title="Postcode:" content={address.postalCode} />
                    <InformationItem title="Plaats:" content={address.city} />
                </ListInformationCard>
            </Row>
        </Container>
    );
}

export default DrivingSchoolInformation;