import React from 'react';
import {useAsyncDebounce} from 'react-table';
import PropTypes from 'prop-types';
import {FormControl, FormGroup} from "react-bootstrap";

const TableGlobalFilter = ({setGlobalFilter, globalFilter, selected = ""}) => {
    const handleChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <td colSpan={10000}>
            <FormGroup className={"mb-0 d-inline"}>
                <FormControl placeholder={"Zoeken"} type={"text"}
                             onKeyUp={e => {
                                 handleChange(e.target.value);
                             }}/>
            </FormGroup>
        </td>
    );
};

TableGlobalFilter.propTypes = {
    setGlobalFilter: PropTypes.func.isRequired,
    globalFilter: PropTypes.string,
};

export default TableGlobalFilter;