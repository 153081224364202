import React from "react";
import ListInformationCard from "../Utils/cards/ListInformationCard";
import InformationItem from "../Utils/cards/InformationItem";
import formatName from "../Utils/FormatName";

/**
 * Gives an overview of the course's students
 * @param course The course to be displayed
 */
export default function CourseStudentsOverview({course}) {
    return (
        <ListInformationCard header={`Medewerkers (${course.studentsPlanned})`} className={"container"}>
            {course.parts[0].slots[0].students.map((s) =><InformationItem title={formatName(s)} />)}
        </ListInformationCard>
    )
}