import {Col, Container, Row} from "react-bootstrap";
import React from "react";

export default function TableBody({getTableBodyProps, pageSize, pageIndex, page, noResults, pageOptions, data, prepareRow, getRowProps=() => {}}) {
    return (
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
            prepareRow(row);
            return (
                <tr {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                </tr>
            );
        })}
        <tr>
            <td colSpan={10000}>
                <Container fluid>
                    <Row className={"justify-content-between"}>
                        {pageSize * pageIndex === 0 && pageSize * pageIndex + page.length === 0 ?
                            <Col>{noResults}</Col> :
                            <>
                                <Col>{`${pageSize * pageIndex + 1}-${pageSize * pageIndex + page.length} van de ${data.length} resultaten`}</Col>
                                <Col
                                    className={"justify-content-end d-flex"}>Pagina {pageIndex + 1} van {pageOptions.length}</Col>
                            </>
                        }
                    </Row>
                </Container>
            </td>
        </tr>
        </tbody>
    )
}