import {authUrlKey} from "../API";
import {browserHistory} from "../App";
import {sendGoogleAnalyticsEvent} from "../components/GoogleAnalytics";
import {companyHandle} from "../Config";
import jwt_decode from 'jwt-decode';

/**
 * Returns the parameter value of the paramKey in the searchString
 * @param searchString The string to be searched
 * @param paramKey The key to be found
 * @returns {string} The value of the paramKey in the searchString
 */
const getParam = function(searchString, paramKey) {
    if (searchString !== "") {
        const parameters = searchString.substring(1).split("&");
        let i;
        for (i = 0; i < parameters.length; i++) {
            let param = parameters[i];
            const key = param.split("=")[0];
            if (key === paramKey) {
                return param.split("=")[1];
            }
        }
    }
    return "";
};

/**
 * Checks the following things:
 * 1:   Check if the URL query contains a token, and sets it in localStorage and refreshes if it is
 * 2:   Check if the current token is valid, and redirects the user to the login page if it is not
 */
export const checkToken = function() {
    if (browserHistory.location.search) {
        const newToken = getParam(browserHistory.location.search, "token");
        if (newToken){
            localStorage.setItem("access-token", newToken);

            sendGoogleAnalyticsEvent({
                hitType: 'event',
                eventCategory: 'Employee',
                eventAction: 'Log in',
                eventLabel: `Handle: ${companyHandle}, Contact: ${jwt_decode(newToken).jti.split(":")[1]}`,
            });
            //Force refresh
            window.location = "/";
        }
    }
    const token = localStorage.getItem("access-token");
    if (token) {
        // Check if the token has expired
        try {
            const tokenParts = JSON.parse(atob(token.split('.')[1]));
            const now = Math.ceil(Date.now() / 1000);
            if (tokenParts.exp <= now) {
                return false;
            }
        } catch {
            return false;
        }
    }  else {
        return false;
    }

    return true;
};
