import React from "react";
import InformationItem from "../Utils/cards/InformationItem";
import ListInformationCard from "../Utils/cards/ListInformationCard";

/**
 * Puts the supplied address in an InformationCard with the supplied title
 * @param title The header of the informationCard
 * @param address The address to be displayed
 */
export default function CompanyAddress({title, address}) {
    return (
        <ListInformationCard header={title} md={6} lg={4}>
            <InformationItem title="Adres:" content={`${address.streetName} ${address.houseNumber} ${address.addition}`} />
            <InformationItem title="Postcode:" content={`${address.postalCode}`} />
            <InformationItem title="Plaats:" content={`${address.city}`} />
        </ListInformationCard>
    )
}