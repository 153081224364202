import axios from 'axios'
import {apiKey, backendUrl, companyHandle, entryPoint} from "./Config";

export const authUrlKey = `https://dashboard.dation.nl/${companyHandle}/login/company_portal_authorization`;

/**
 * Axios instance to communicate with the API, will contain the access token if it is set in local storage.
 * @type {AxiosInstance}
 */
const API = axios.create({
    baseURL: entryPoint,
    timeout: 5000,
    headers: {
        'Authorization': localStorage.getItem('access-token') ? `Bearer ${localStorage.getItem('access-token')}` : '',
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
});

/**
 * Axios instance to communicate with the API, uses the API key instead of the Bearer token.
 * @type {AxiosInstance}
 */
export const DATION_API = axios.create({
    baseURL: entryPoint,
    timeout: 5000,
    headers: {
        'Authorization': 'Basic ' + apiKey,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
});

/**
 * Axios instance to communicate with the Backend
 * @type {AxiosInstance}
 */
export const BACKEND = axios.create({
    baseURL: backendUrl,
    timeout: 5000,
    headers: {
        'Authorization': localStorage.getItem('access-token') ? `Bearer ${localStorage.getItem('access-token')}` : '',
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
})

// Redirect the user to the login page if the token is not valid anymore
API.interceptors.response.use(response => response,
    error => {
        if (error.response && error.response.status === 401 &&
            error.response.statusText === "Unauthorized") {
            return window.location = authUrlKey;
        }
        return Promise.reject(error);
    });

export default API;
