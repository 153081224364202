/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import DateTime from 'react-datetime';
import '../../../style/DateInput.scss';
import moment from "../../../Config";

const DateInput = ({
    placeholder, value, onChange, disabled = false, required = false,
}) => {
    const dateInputRef = useRef(null);

    const openDatePicker = () => {
        dateInputRef.current.setState({ open: true });
    };

    return (
        <div className="d-flex">
            <DateTime
                ref={dateInputRef}
                dateFormat="L"
                timeFormat={false}
                closeOnSelect
                inputProps={{
                    placeholder,
                    pattern: '(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}',
                    title: 'DD-MM-YYYY',
                    disabled,
                    required,
                }}
                className="react-datepicker-component w-100"
                value={value}
                onChange={onChange}
            />
            <div className="flex-shrink-1">
                <InputGroup.Append className="h-100">
                    <InputGroup.Text className="date-picker-append"><span className="glyphicons glyphicons-calendar" onClick={() => !disabled && openDatePicker()} /></InputGroup.Text>
                </InputGroup.Append>
            </div>
        </div>
    );
};

DateInput.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.instanceOf(moment) | PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
};
export default DateInput;