export const danger = {
    position: 'top-center',
    style: {
        backgroundColor: '#f8d7da',
        borderColor: '#f5c6cb',
        color: '#721c24',
    }
};

export const success = {
    position: 'top-center',
    style: {
        backgroundColor: '#d4edda',
        borderColor: '#c3e6cb',
        color: '#155724',
    }
};