import {Button, ButtonGroup, Col, Form} from "react-bootstrap";
import React, {useContext} from "react";
import {CompanyContext} from "../../util/CompanyContext";

/**
 * Simple pagination component which contains arrows, amount per page and an option to jump to a page.
 * @param canPreviousPage
 * @param canNextPage
 * @param previousPage
 * @param nextPage
 * @param gotoPage
 * @param pageCount
 * @param pageIndex
 * @param pageOptions
 * @param pageSize
 * @param setPageSize
 * @returns {*} The jsx of the component
 */
export default function Pagination({
                                       canPreviousPage,
                                       canNextPage,
                                       previousPage,
                                       nextPage,
                                       gotoPage,
                                       pageCount,
                                       pageIndex,
                                       pageOptions,
                                       pageSize,
                                       setPageSize,
                                   }) {
    const company = useContext(CompanyContext);
    return (
        <div className="pagination row align-items-center">
            <Col xs={12} md={4} className={"mb-2"}>
                <ButtonGroup className={"w-100"}>
                    <Button variant={`${company.handle} d-flex align-items-center justify-content-center`}
                            onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <i className={"material-icons"}>first_page</i>
                    </Button>
                    <Button variant={`${company.handle} d-flex align-items-center justify-content-center`}
                            onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <i className={"material-icons"}>chevron_left</i>
                    </Button>
                    <Button variant={`${company.handle} d-flex align-items-center justify-content-center`}
                            onClick={() => nextPage()} disabled={!canNextPage}>
                        <i className={"material-icons"}>chevron_right</i>
                    </Button>
                    <Button variant={`${company.handle} d-flex align-items-center justify-content-center`}
                            onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <i className={"material-icons"}>last_page</i>
                    </Button>
                </ButtonGroup>
            </Col>
            <Col xs={6} md={4} className={"mb-2"}>
                <span>Ga naar pagina</span>
                <div className={"d-inline-flex"}>
                    <Form.Control
                        type="number"
                        defaultValue={pageIndex + 1}
                        min={1}
                        max={pageOptions.length}
                        step={1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        className={"ml-2"}
                    />
                </div>
            </Col>
            {setPageSize &&
            <Col xs={6} md={4} className={"mb-2"}>
                <Form.Group className={"mb-0"}>
                    <Form.Control as={"select"} custom
                                  value={pageSize}
                                  onChange={e => {
                                      setPageSize(Number(e.target.value))
                                  }}>
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Toon {pageSize}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Col>
            }
        </div>
    );
}