import {Col, Row} from "react-bootstrap";
import React from "react";
import CompanyAddress from "./CompanyAddress";
import CompanyOverview from "./CompanyOverview";

/**
 * Displays general information about the company, including visiting and billing addresses
 * @param company The company to be displayed
 */
export default function GeneralCompanyInformation({company}) {
    return (
        <Row>
            <Col xs={12} className={"my-3 px-0"}>
                <h2 className={"display-4"}>Algemene informatie:</h2>
            </Col>
            <CompanyOverview company={company}/>
            {company.visitingAddress && <CompanyAddress address={company.visitingAddress} title={"Bezoekadres"}/>}
            {company.billingAddress && <CompanyAddress address={company.billingAddress} title={"Factuuradres"}/>}
        </Row>
    )
}