import React from "react";
import InformationItem from "../Utils/cards/InformationItem";
import ListInformationCard from "../Utils/cards/ListInformationCard";

/**
 * Gives a general overview of the company in an InformationCard
 * @param company The company to be displayed
 */
export default function CompanyOverview({company}) {
    return (
        <ListInformationCard header={"Bedrijfsgegevens"} md={6} lg={4}>
            <InformationItem title="Naam:" content={company.name} />
            <InformationItem title="E-mailadres:" content={company.emailAddress} />
            <InformationItem title="Telefoonnummer:" content={company.phoneNumber} />
            <InformationItem title="KVK:" content={company.companyRegistrationNumber} />
            <InformationItem title="Btw-id:" content={company.vatNumber} />
        </ListInformationCard>
    )
}