import TableGlobalFilter from "./TableGlobalFilter";
import React from "react";

export default function TableHeader({setGlobalFilter, globalFilter, headerGroups}) {
    return (
        <thead>
        {globalFilter && <TableGlobalFilter setGlobalFilter={setGlobalFilter} globalFilter={globalFilter} selected={""} /> }
        {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <>{column.render('Header')}</>
                            <i className={"material-icons"}>
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? 'arrow_upward'
                                        : 'arrow_downward'
                                    : ''
                                }
                            </i>
                        </div>
                    </th>
                ))}
            </tr>
        ))}
            </thead>
    )
}