import moment from 'moment';
import nl from 'moment/locale/nl';

moment.updateLocale('nl', nl);

export default moment;

export const companyHandle = process.env.REACT_APP_COMPANY_HANDLE;
export const logoSource = process.env.REACT_APP_LOGO_SOURCE;
export const apiKey = process.env.REACT_APP_API_KEY;

export const backendUrl = process.env.REACT_APP_BACKEND_URL || "https://europe-west1-dation.cloudfunctions.net/company-portal-backend-production";

export const entryPoint = process.env.REACT_APP_ENTRYPOINT || "https://dashboard.dation.nl/api/v1/";

export const administrationId = process.env.REACT_APP_ADMINISTRATION_ID || 1;

export const addEmployeesEnabled = !!process.env.REACT_APP_ADD_EMPLOYEES;

