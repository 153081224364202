import jwt_decode from "jwt-decode";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select, { components } from "react-select";
import { useSnackbar } from "react-simple-snackbar";
import { BACKEND } from "../../API";
import moment from "../../Config";
import { CompanyContext } from "../../util/CompanyContext";
import { useData } from "../Hooks/DataHook";
import { danger, success } from "../Utils/AlertStyles";
import CustomCard from "../Utils/cards/CustomCard";
import RequiredStar from "../Utils/forms/RequiredStar";

const OrderForm = ({ course, setShow }) => {
    const [plannedStudents, setPlannedStudents] = useState(1);
    const [participants, setParticipants] = useState([]);

    const {
        drivingSchoolData,
        updateDrivingSchoolData,
        companyInformation,
        currentCompany,
        employeeData,
        updateEmployeeData
    } = useData();

    useEffect(() => {
        if (!drivingSchoolData.done) updateDrivingSchoolData();
        if (!employeeData.done) updateEmployeeData();
    }, []);

    const [addSuccess] = useSnackbar(success); //const [addSuccess, closeSuccess] = useSnackbar(success);
    const [addAlert] = useSnackbar(danger); //const [addAlert, closeAlert] = useSnackbar(danger);

    const clearFields = () => {
        setPlannedStudents(1);
        setParticipants([]);
    };

    const company = useContext(CompanyContext);

    const sendEmails = (event) => {
        setShow(false);
        event.preventDefault();

        const currentCompanyData = companyInformation.data.find(
            (item) => parseInt(item.id) === parseInt(currentCompany)
        );
        const requestingContactPerson = currentCompanyData.contactPersons.find(
            (person) =>
                person.emailAddress ===
                jwt_decode(localStorage.getItem("access-token"))
                    .jti.split(":")[1]
                    .slice(0, -1)
        );

        const data = {
            schoolName: drivingSchoolData.data.name,
            schoolEmail: drivingSchoolData.data.emailAddress,
            email: currentCompanyData.emailAddress,
            schoolPhoneNumber: drivingSchoolData.data.phoneNumber,
            name: `${requestingContactPerson.firstName} ${requestingContactPerson.lastName}`,
            trainingName: course.name,
            companyName: currentCompanyData.name,
            phoneNumber: currentCompanyData.phoneNumber,
            plannedStudents,
            date: moment(course.startDate).format(),
            location: course.parts?.[0].slots?.[0].location.address.city || "",
            participants: participants.map((item) => item.value),
            logo: drivingSchoolData.data.logo
        };

        BACKEND.post("/mail/send", JSON.stringify(data))
            .then((r) => {
                if (r.status === 200) {
                    addSuccess("Reservering succesvol geplaatst!");
                    clearFields();
                } else {
                    addAlert("Er ging iets mis");
                }
            })
            .catch((e) => {
                if (!e.response) {
                    addAlert(
                        "Server error, neem contact op met Dation om het te verhelpen"
                    );
                } else if (e.response.status === 401) {
                    addAlert(
                        "Je hebt geen toegang, probeer opnieuw in te loggen"
                    );
                } else if (e.response.status === 400) {
                    addAlert(
                        "De ingevulde waardes zijn ongeldig, kijk of er iets ontbreekt"
                    );
                } else {
                    addAlert("Er ging iets mis");
                }
            });
    };

    const selectOptions = useMemo(
        () =>
            employeeData.data.map((employee) => ({
                label: `${employee.firstName} ${employee.lastName}`,
                value: {
                    firstName: employee.firstName,
                    lastName: employee.lastName
                }
            })),
        [employeeData]
    );

    const Menu = (props) => {
        const optionSelectedLength = props.getValue().length || 0;
        return (
            <components.Menu {...props}>
                {optionSelectedLength < course.remainingAttendeeCapacity ? (
                    props.children
                ) : (
                    <div style={{ margin: 15 }}>
                        De beschikbare plekken zijn zo gevuld
                    </div>
                )}
            </components.Menu>
        );
    };

    return (
        <>
            <Row noGutters className={"w-100 mt-3"}>
                <CustomCard header={"Medewerkers"}>
                    <Form onSubmit={sendEmails}>
                        <Form.Group as={Row} className={"align-items-center"}>
                            <Form.Label as={Col}>
                                Kies het aantal te reserveren plaatsen:
                                <RequiredStar />
                            </Form.Label>
                            <Col>
                                <Form.Control
                                    as={"select"}
                                    type={"select"}
                                    value={plannedStudents}
                                    onChange={(e) =>
                                        setPlannedStudents(
                                            e.currentTarget.value
                                        )
                                    }
                                >
                                    {[
                                        ...Array(
                                            course.remainingAttendeeCapacity
                                        )
                                    ].map((_, i) => (
                                        <option key={i} value={i + 1}>
                                            {i + 1}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={"align-items-center"}>
                            <Form.Label as={Col}>
                                Selecteer medewerker(s):
                            </Form.Label>
                            <Col>
                                <Select
                                    isMulti
                                    value={participants}
                                    onChange={(value) => {
                                        if (value.length > plannedStudents)
                                            setPlannedStudents(value.length);
                                        setParticipants(value);
                                    }}
                                    options={selectOptions}
                                    blurInputOnSelect={false}
                                    closeMenuOnSelect={false}
                                    placeholder={"Selecteer"}
                                    styles={{
                                        control: (provided, state) => {
                                            if (state.isFocused) {
                                                return {
                                                    ...provided,
                                                    borderColor:
                                                        "rgb(136, 191, 251)",
                                                    boxShadow: ` 0 0 0 0.2rem rgba(0, 123, 255, 0.25)`,
                                                    outline: `0 none`
                                                };
                                            } else {
                                                return { ...provided };
                                            }
                                        }
                                    }}
                                    components={{ Menu }}
                                />
                            </Col>
                        </Form.Group>
                        <div className={"w-100 d-flex justify-content-between"}>
                            <Button
                                variant={"outline-secondary"}
                                onClick={() => {
                                    clearFields();
                                    setShow(false);
                                }}
                            >
                                Annuleren
                            </Button>
                            <Button variant={company.handle} type={"submit"}>
                                Reserveer
                            </Button>
                        </div>
                    </Form>
                </CustomCard>
            </Row>
        </>
    );
};

export default OrderForm;
