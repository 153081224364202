import {Container, Row} from "react-bootstrap";
import GeneralStudentInformation from "./GeneralStudentInformation";
import React from "react";

/**
 * Displays general information about the student
 * @param student The student to be displayed
 */
export default function StudentInformation({student}) {
    return (
        <Container>
            <Row className={"my-3"}>
                <GeneralStudentInformation student={student}/>
            </Row>
        </Container>
    )
}