import React from 'react';
import {Route} from 'react-router-dom';

export const sendGoogleAnalyticsEvent = options => {
    window.ga('send', options);
};

export const sendPageView = location => {
    window.ga('set', 'page', location.pathname + location.search);
    window.ga('send', 'pageview');
};

// Component to be used in each router, outside of the Switch component.
// The render function will trigger on each page view and the location will be sent to GA
const SendPageViewRoute = () => {
    return (
        <Route
            path="/"
            render={({location}) => {
                sendPageView(location);
                return null;
            }}
        />
    );
}
export default SendPageViewRoute;