import React from "react";
import InformationItem from "../Utils/cards/InformationItem";
import ListInformationCard from "../Utils/cards/ListInformationCard";
import formatName from "../Utils/FormatName";

/**
 * Displays person information in an InformationCard
 * @param person The person to be displayed
 */
export default function ContactPerson({person}) {
    return (
        <ListInformationCard md={12} lg={6} header={formatName(person)}>
            <InformationItem title="E-mailadres:" content={person.emailAddress} />
            <InformationItem title="Telefoonnummer:" content={person.phoneNumber} />
        </ListInformationCard>
        )
}