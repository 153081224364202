import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { Button, Row, Table } from "react-bootstrap";
import React, { useContext, useMemo, useState } from "react";
import NoData from "../Utils/NoData";
import moment, { addEmployeesEnabled } from "../../Config";
import Code95Progress from "./Code95Progress";
import CertificationRisk from "./CertificationRisk";
import Pagination from "../Tables/Pagination";
import TableHeader from "../Tables/TableHeader";
import TableBody from "../Tables/TableBody";
import { insertion } from "../Utils/FormatName";
import { useData } from "../Hooks/DataHook";
import { CompanyContext } from "../../util/CompanyContext";
import AddEmployeeModal from "./AddEmployeeModal";

/**
 * The table used to display the current employees on the EmployeePage
 * @param employeeData The data retrieved by EmployeePage.js
 * @param setStudentId Used to determine which modal to open
 * @returns {*} The jsx of the component
 */
export default function EmployeeTable({ setStudentId, setShowModal }) {
    const { employeeData } = useData();
    const data = useMemo(() => (employeeData.data), [employeeData]);

    const [addEmployeeModal, setAddEmployeeModal] = useState(false);

    const company = useContext(CompanyContext);

    const columns = useMemo(() => [
        {
            Header: 'Achternaam',
            accessor: (person) => person.lastName + insertion(person, true),
            Cell: ({ cell: { value } }) => value ? value : <NoData />,
        },
        {
            Header: 'Voornaam',
            accessor: 'firstName',
            Cell: ({ cell: { value } }) => value ? value : <NoData />,
        },
        {
            Header: 'Geboortedatum',
            accessor: 'dateOfBirth',
            Cell: ({ cell: { value } }) => value ? moment(value).format('L') : <NoData />,
        },
        {
            Header: 'Verloopdatum rijbewijs',
            accessor: (person) => person.drivingLicense ? person.drivingLicense.expiryDate || "" : "",
            Cell: ({ cell: { value } }) => value ? moment(value).format('L') : <NoData />,
        },
        {
            Header: 'Verloopdatum code\u00a095',
            accessor: (person) => person.currentCycle ? person.currentCycle.endDate || "" : "",
            Cell: ({ cell: { value } }) => value ? moment(value).format('L') : <NoData />,
        },
        {
            Header: 'Voortgang code\u00a095',
            accessor: (person) => {
                return {
                    totalCredits: person.currentCycle.totalCredits ? person.currentCycle.totalCredits : 0,
                    code95: person.currentCycle.endDate ? person.currentCycle.endDate : null,
                    practice: person.currentCycle.practiceCredits
                }
            },
            sortType: (a, b) => a.values['Voortgang code\u00a095'].totalCredits + a.values['Voortgang code\u00a095'].practice > b.values['Voortgang code\u00a095'].totalCredits + b.values['Voortgang code\u00a095'].practice ? 1 : -1,
            Cell: ({ cell: { value } }) => <Code95Progress credits={value.totalCredits}
                endDate={value.code95 ? moment(value.code95) : moment()}
                practice={value.practice} />,
        },
        {
            Header: 'Praktijk behaald',
            accessor: (person) => person.currentCycle.practiceCredits,
            sortType: (a, b) => a.values['Praktijk behaald'] && !b.values['Praktijk behaald'] ? 1 : -1,
            Cell: ({ cell: { value } }) => value ?
                <i className={"text-success material-icons"}>done</i> :
                <i className={"text-danger material-icons"}>close</i>,
        },
        {
            Header: 'Certificaat risico',
            accessor: 'certificationRisk',
            Cell: ({ cell: { value } }) => value ? <CertificationRisk certificationRisk={value} /> : <NoData />
        },
        {
            Header: 'id',
            accessor: 'id',
        },
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0, hiddenColumns: ['id'],
                sortBy: [
                    {
                        id: 'Achternaam',
                        desc: false
                    }
                ]
            },

        },
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    const getRowProps = row => ({
        onClick: () => onRowClick(row),
        style: {
            cursor: "pointer"
        }
    });

    function onRowClick(row) {
        setStudentId(row.original.id);
        setShowModal(true);
    }
    return (
        <>
            <div className={"container-xl transition"}>
                <Row className={"my-3"}>
                    <div className={"d-flex justify-content-between w-100"}>
                        <h4 className={"display-4"}>Medewerkers</h4>
                        {
                            addEmployeesEnabled &&
                            <div className={"d-flex align-items-center"}>
                                <Button variant={company.handle} onClick={() => setAddEmployeeModal(true)}>Medewerker
                                    toevoegen</Button>
                            </div>
                        }
                    </div>
                    <Table className={"mb-0"} responsive striped bordered hover {...getTableProps()}>
                        <TableHeader globalFilter={globalFilter} setGlobalFilter={setGlobalFilter}
                            headerGroups={headerGroups} />
                        <TableBody data={data} getTableBodyProps={getTableBodyProps}
                            noResults={"Geen medewerkers gevonden"} page={page} pageIndex={pageIndex}
                            pageOptions={pageOptions} prepareRow={prepareRow} pageSize={pageSize}
                            getRowProps={getRowProps} />
                    </Table>
                </Row>
                <Pagination canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    setPageSize={setPageSize} />

            </div>
            <AddEmployeeModal show={addEmployeeModal} setShow={setAddEmployeeModal} />
        </>
    );
}