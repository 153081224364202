import React, {useContext} from "react";
import {Container, Modal, Spinner} from "react-bootstrap";
import OrderOverview from "./OrderOverview";
import OrderForm from "./OrderForm";
import {CompanyContext} from "../../util/CompanyContext";

const ReservationsModal = ({show, setShow, course = {}, setCourse}) => {
    const company = useContext(CompanyContext);

    return (
        <Modal
            show={show}
            onHide={() => {
                setShow(false);
            }}
            size={"lg"}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Reserveren
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"mb-3"}>
                <Container>
                    {course ?
                        <>
                            <OrderOverview course={course}/>
                            <OrderForm course={course} setShow={setShow}/>
                        </>
                        :
                        <div className={"d-flex"}>
                            <Spinner className={"my-auto mx-auto"} animation={"border"} variant={company.handle}/>
                        </div>
                    }
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default ReservationsModal;