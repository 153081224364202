import React from "react";
import {css} from "emotion";

/**
 * General way of displaying one information item
 * @param title The title of the item
 * @param content The content of the item
 * @returns {*}
 * @constructor
 */
export default function InformationItem({title, content}) {
    return (
        <tr className={css`
          border: 1px solid rgba(0, 0, 0, .125);
          padding: .75rem 1.25rem
        `}>
            <td className={"p-2 font-weight-bold " + css`
              width: 0.1%;
              white-space: nowrap;
            `}>{title}</td>
            <td className={"p-2"}>{content}</td>
        </tr>
    )
}